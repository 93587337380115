import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import 'twin.macro';

import Layout from '../components/Layout';
import { Content, Locales } from '../types/QueryTypes';
import TextAndImage from '../components/TextAndImage';
import MinScreenHeightContainer from '../components/MinScreenHeightContainer';

// MARK: - PageQuery
export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { eq: "common" }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    exhibitions: allContent(filter: { slug: { glob: "/exhibitions*" } }) {
      nodes {
        id
        slug
        title
        content {
          assets {
            title
            year
            material
            size
            image {
              childImageSharp {
                gatsbyImageData(quality: 90, placeholder: BLURRED)
              }
              id
            }
          }
          text {
            de {
              ...FileFragment
            }
            en {
              ...FileFragment
            }
          }
        }
      }
    }
  }

  fragment FileFragment on File {
    childMarkdownRemark {
      html
    }
  }
`;

// MARK:- Parameter Type Declaration
interface DataProps {
  locales: Locales;
  exhibitions?: {
    nodes?: [
      {
        id: string;
        position: number;
        slug?: string;
        title?: string;
        content?: Content;
      }
    ];
  };
}

// MARK:- Component
function Exhibitions({ data }: PageProps<DataProps>) {
  const sections = data.exhibitions?.nodes;
  const { language } = useI18next();

  return (
    <Layout>
      <MinScreenHeightContainer>
        {sections?.map(({ id, title, content }) => {
          const html = content?.text?.[language]?.childMarkdownRemark?.html;
          const image =
            content?.assets?.[0].image?.childImageSharp?.gatsbyImageData;

          return (
            html &&
            image && (
              <TextAndImage
                key={id}
                html={html}
                imageTitle={title ?? 'exhibition image'}
                image={image}
              />
            )
          );
        })}
      </MinScreenHeightContainer>
    </Layout>
  );
}

export default Exhibitions;
